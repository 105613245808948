<template>
  <b-container class="no-gutters bg-white py-3">
    <b-col lg="6" md="6" sm="12" xs="12" class="mb-3">
      <b-form-checkbox
        id="enable-member-point"
        v-model="form.is_point"
        :value="1"
        :unchecked-value="0"
        name="enable-member-point"
        @change="setForm($event)"
      >
        Enable
      </b-form-checkbox>
    </b-col>
    <b-row class="align-items-center p-3 row-gap-md">
      <b-col cols="2" class="bg-custom-primary header-point text-center">
        <div>Member Tier ID.</div>
      </b-col>

      <b-col cols="4" class="bg-custom-primary header-point text-center">
        <div>Member Tier Name</div>
      </b-col>
      <b-col cols="4" class="bg-custom-primary header-point">
        Baht Per Point
      </b-col>
      <b-col cols="2" class="bg-custom-primary header-point"> </b-col>
      <div class="break-normal"></div>
      <template v-for="(list, index) of form.branch_point">
        <b-col
          cols="2"
          :key="'member-id' + list.member_level_id"
          class="text-center"
        >
          <div>{{ list.member_level_id }}</div>
        </b-col>
        <b-col
          cols="4"
          :key="'member-name' + list.member_level_id"
          class="text-center"
        >
          <div>{{ list.member_level_name }}</div>
        </b-col>
        <b-col cols="4" :key="'member-Baht' + list.member_level_id">
          <InputText
            textFloat=""
            class="mb-0"
            placeholder="Baht Per Point"
            type="number"
            v-model="list.baht_per_point"
            :disabled="form.is_point == 0"
            :oninput="'javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, 8);'"
            :isValidate="
              v.form.branch_point.$each.$iter[index].baht_per_point.$error
            "
            :useDot="false"
            :v="v.form.branch_point.$each.$iter[index].baht_per_point"
          />
        </b-col>
        <b-col cols="2" :key="'member-Baht' + list.member_level_id">
          <div
            @click="setToDefault(list)"
            :class="form.is_point ? 'cursor-pointer' : ''"
          >
            Set Default
          </div>
        </b-col>
        <div
          class="break-normal"
          :key="'member-space' + list.member_level_id"
        ></div>
      </template>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: {
    form: {
      required: true,
    },
    v: {
      required: false,
    },
    originBranchPoint: {
      required: false,
    },
  },
  data() {
    return {};
  },

  methods: {
    setForm(val) {
      if (val == 0) {
        let originPoint = this.originBranchPoint.map((el) => {
          let point = this.form.branch_point.find(
            (a) => a.member_level_id == el.member_level_id
          );
          return { ...el, id: point.id };
        });
        this.form.branch_point = JSON.parse(JSON.stringify(originPoint));
      }
    },
    setToDefault(list) {
      if (this.form.is_point == 1) {
        let baht = this.originBranchPoint.find(
          (el) => el.member_level_id == list.member_level_id
        );
        list.baht_per_point = baht.baht_per_point;
      }
    },
  },
};
</script>

<style>
.header-point {
  padding: 12px;
  font-size: 16px;

  height: 48px;
}
</style>
